import { lock, unlock } from 'tua-body-scroll-lock';
import {
    headerElement,
    mobileScrollContainers,
} from './dom-elements.js';

/**
 * Toggles the scroll lock based on the mobile menu state.
 * If the mobile menu is active, unlock the scroll.
 * Otherwise, lock the background scroll but enable scrolling on the subheader.
 */
export const handleScrollLock = () => {
    headerElement.classList.contains('header--mobile-menu-active')
        ? unlock()
        : lock(mobileScrollContainers, {
            overflowType: 'clip',
        });
};
