import {
    initIntersectionObserver,
    initFallbackScrollListener,
} from './intersection-observer';
import { toggleSubheader } from './toggle-subheader';
import { toggleSubheaderButtons, toggleMobileMenuButton } from './dom-elements';
import { toggleMobileMenu } from './toggle-mobile-menu';
import { handleScrollLock } from './handle-scroll-lock.js';

export const initHeader = () => {
    /**
     * Initialise intersection observer
     * Fallback to scroll listener if Intersection observer not supported
     */
    if (window.IntersectionObserver) {
        initIntersectionObserver();
    } else {
        initFallbackScrollListener();
    }

    /**
     * Toggle subheader
     */
    for (const button of toggleSubheaderButtons ?? []) {
        button.addEventListener('click', () => toggleSubheader(button));
    }

    /**
     * Toggle mobile menu
     */
    toggleMobileMenuButton?.addEventListener('click', () => {
        handleScrollLock();
        toggleMobileMenu();
    });

    document.querySelector('body').addEventListener('click', event => {
        if (event.target.closest('.header__item')) {
            return
        }

        const openSubmenu = document.querySelector('.header__item--active .sub-header')

        if (!openSubmenu) {
            return;
        }

        // Check if we have clicked on something inside the open subheader and bail if we have
        if (event.target.closest('.sub-header')) {
            return;
        }

        openSubmenu.closest('.header__item')?.classList.remove('header__item--active')
    })
};
