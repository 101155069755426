import { toggleSubheaderButtons } from './dom-elements';

const deactivateAllSubheaders = () => {
    for (const btn of toggleSubheaderButtons) {
        btn.parentElement.classList.remove('header__item--active');
    }
};

export const toggleSubheader = button => {
    const subheaderContainer = button.parentElement;
    const isActive = subheaderContainer.classList.contains(
        'header__item--active'
    );

    deactivateAllSubheaders();

    subheaderContainer.classList.toggle('header__item--active', !isActive);
};
