import { headerElement } from './dom-elements';

/**
 * Initializes Intersection Observer to manage header position based on scrolling.
 */
export const initIntersectionObserver = () => {
    const sentinel = document.querySelector('[data-sentinel]');
    const observer = new IntersectionObserver(
        entries => {
            const position = entries[0].isIntersecting ? 'static' : 'sticky';
            headerElement.setAttribute('data-position', position);
        },
        { threshold: 1.0 }
    );
    observer.observe(sentinel);
};

/**
 * Initializes a fallback scroll listener for browsers without IntersectionObserver support.
 */
export const initFallbackScrollListener = () => {
    window.addEventListener('scroll', () => {
        const position = window.scrollY > 0 ? 'static' : 'sticky';
        headerElement.setAttribute('data-position', position);
    });
};
