export const headerElement = document.querySelector('[data-header]');

export const toggleSubheaderButtons = document.querySelectorAll(
    '[data-toggle-submenu]'
);

export const toggleMobileMenuButton = document.querySelector(
    '[data-toggle-mobile-menu]'
);

export const mobileScrollContainers = Array.from(
    document.querySelectorAll('[data-subheader-scroll]')
);
